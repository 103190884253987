import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Modal,
  TabContent,
  TabPane,
  Tooltip,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import "./style.css";
// Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

// Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CourseListTable from "./CourseTable/courseListTable";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Loader } from "rsuite";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { userData } from "../../../store/getProfileData";

const ComingSoonCourses = () => {
  document.title = "Courses | Matary ";
  const navigate = useNavigate();

  const [Courses, setCourses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [univs, setUnivs] = useState(false);
  const [filteredCourses, setFilteredCourses] = useState();
  const getCourses = async () => {
    setLoading(true);
    const courses = await axios.get(
      "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/courses/select_courses.php"
    );
    //
    setCourses([...courses?.filter((item) => item?.comming_soon == "yes")]);
    setFilteredCourses([
      ...courses?.filter((item) => item?.comming_soon == "yes"),
    ]);
    setLoading(false);
  };

  const showHideCourse = async (send_data) => {
    //
    const courses = await axios.post(
      "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/courses/show_hide_course.php",
      JSON.stringify(send_data)
    );
    //
    if (courses.status == 'success') {
      toast.success(courses.message);
      getCourses();
      // console.log("getCourses");
    } else if (courses.status == 'error') {
      toast.error(courses.message);
    } else {
      toast.error("Something Went Error");
    }
  };

  const getUnivs = async () => {
    const selct_univs = await axios.get(
      "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/universities/select_universities_grade.php"
    );
    setUnivs(selct_univs.message);
  };

  const [selectedUnivs, setSelectedUnivs] = useState(false);

  useEffect(() => {
    getCourses();
    getUnivs();
  }, []);
  const [filterGrades, setFilterGrades] = useState(false);
  const [selectGrades, setSelectGrades] = useState(false);
  const getFilterGrades = () => {
    setFilterGrades(
      univs?.filter((item) => item.university_id == selectedUnivs)[0]?.grades
    );
  };
  useEffect(() => {
    let arr = [];
    if (filteredCourses && filteredCourses.length)
      arr = filteredCourses.filter(
        (item) => item?.university_id == selectedUnivs
      );
    setCourses([...arr]);
    if (selectedUnivs) {
      getFilterGrades();
    } else {
      setFilterGrades(false);
      setCourses(filteredCourses);
    }
  }, [selectedUnivs]);

  useEffect(() => {
    let arr = [];
    if (filteredCourses && filteredCourses.length) {
      if (selectGrades) {
        arr = filteredCourses.filter((item) => item?.grade_id == selectGrades);
        setCourses([...arr]);
      } else {
        arr = filteredCourses.filter(
          (item) => item?.university_id == selectedUnivs
        );
        setCourses([...arr]);
      }
    }
  }, [selectGrades]);
  const permissions = userData?.permissions;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Comming Soon Courses" 
            breadcrumbItem="Course List"
          />
           <div className="univs-container">
            <div className="univs-section">
              {univs && univs.length
                ? univs.map((item, index) => {
                    return (
                      <span
                        key={index}
                        className={`univ-item ${
                          selectedUnivs === item?.university_id ? "active" : ""
                        }`}
                        onClick={() =>
                          selectedUnivs === item?.university_id
                            ? setSelectedUnivs(false)
                            : setSelectedUnivs(item?.university_id)
                        }
                      >
                        {item?.university_name}
                      </span>
                    );
                  })
                : null}
            </div>

            <div className="grades-section">
              {filterGrades && filterGrades.length && selectedUnivs
                ? filterGrades.map((item, index) => {
                    return (
                      <span
                        key={index}
                        className={`grade-item ${
                          selectGrades === item?.grade_id ? "active" : ""
                        }`}
                        onClick={() =>
                          selectGrades === item?.grade_id
                            ? setSelectGrades(false)
                            : setSelectGrades(item?.grade_id)
                        }
                      >
                        {item?.grade_name}
                      </span>
                    );
                  })
                : null}
            </div>
          </div>

          
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="position-relative">
                    <div className="modal-button mt-2">
                      <Row className="align-items-start">
                        <Col className="col-sm">
                          
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div id="table-invoices-list">
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <CourseListTable
                        btnChild={<div>
                            {permissions?.includes("*1_1") ||
                            permissions?.startsWith("1_1") ||
                            permissions == "all" ? (
                              <button
                                type="button"
                                className="btn btn-success mb-4"
                                data-bs-toggle="modal"
                                data-bs-target="#addCourseModal"
                                onClick={() => {
                                  navigate("/courses-list/add-course");
                                }}
                              >
                                <i className="mdi mdi-plus me-1"></i>
                                Add Course
                              </button>
                            ) : null}
                          </div>}
                          Courses={Courses}
                          showHideCourse={showHideCourse}
                          getCourses={getCourses}
                        />
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default ComingSoonCourses;
